import { useEffect } from "react";

import {
  isLogoutInProgressAtom,
  parseStringParameter,
  useAuthLogin,
  useIsAuthenticated,
} from "@keepeek/commons";
import { useRouter } from "next/router";
import { useRecoilState, useRecoilValue } from "recoil";

import logger from "../../../lib/logger-utils";
import { autoIdpAuthLoadingAtom } from "../atoms/autoIdpAuth";
import { useAuthProps } from "./useAuthProps";
import { useKeycloakConfig } from "./useKeycloakConfig";

export const useAutoIdpAuth = () => {
  const { query } = useRouter();
  const { loading: loadingKCConfig } = useKeycloakConfig();
  const { loading: loadingAuth, isAuthenticated } = useIsAuthenticated();
  const { login } = useAuthLogin(useAuthProps());

  const isLogoutInProgressState = useRecoilValue(isLogoutInProgressAtom);
  const [inProgress, setInProgress] = useRecoilState(autoIdpAuthLoadingAtom);

  const idp = parseStringParameter(query.idp) || "";

  const loading = loadingKCConfig || loadingAuth || inProgress;

  const shouldAutoIdpAuth = !loading && !isLogoutInProgressState && !isAuthenticated && !!idp;

  useEffect(() => {
    if (shouldAutoIdpAuth) {
      logger.debug(`useAutoIdpAuth - try to auto log idp ${idp}`);
      setInProgress(true);
      login(idp);
    }
  }, [login, setInProgress, shouldAutoIdpAuth, idp]);

  return { loading };
};
