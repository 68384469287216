import React, { ReactNode } from "react";

import { logger } from "@sentry/utils";
import Image, { ImageProps } from "next/image";

import { useImageOptim } from "../../providers/config/hooks/useImageOptim";

const ImageOptimComponent = function ({ src, alt, onError, ...rest }: ImageProps) {
  const { crash, evaluateSrc } = useImageOptim();
  const handleCrash = (e: any) => {
    onError && onError(e);
    crash(src as string);
  };
  if (typeof src === "string" && !evaluateSrc(src)) {
    return null;
  }
  return <Image src={src} alt={alt} onError={handleCrash} {...rest} />;
};

class ImageOptim extends React.Component<
  ImageProps & { children: ReactNode },
  { hasError: boolean }
> {
  state: { hasError: boolean } = {
    hasError: false,
  };
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    logger.debug("ImageOptim, not reavaluating because of", error);
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    logger.debug("ImageOptim, fallback because of", { error, errorInfo });
    this.setState({ hasError: true });
  }

  render() {
    const { src, alt, children, ...rest } = this.props;
    if (this.state.hasError) {
      return children;
    }
    return <ImageOptimComponent alt={alt} src={src} {...rest} />;
  }
}

export default ImageOptim;
