import { useMemo } from "react";

import { useRouter } from "next/router";

import { QUERY_BACKDOOR } from "../models";

export const useBackdoors = () => {
  const { query } = useRouter();

  const displayConnectWithCredentials = useMemo(
    () => QUERY_BACKDOOR.DISPLAY_CONNECT_WITH_CREDENTIALS in query,
    [query],
  );

  const displayAdminImportConfiguration = useMemo(
    () => QUERY_BACKDOOR.DISPLAY_ADMIN_IMPORT_CONFIGURATION in query,
    [query],
  );

  const displayAuthTests = useMemo(() => QUERY_BACKDOOR.DISPLAY_AUTH_TESTS in query, [query]);

  const iAmAGuest = useMemo(() => QUERY_BACKDOOR.AUTO_GUEST_AUTH in query, [query]);
  const stayOnLanding = useMemo(() => QUERY_BACKDOOR.STAY_ON_LANDING_AUTH in query, [query]);
  const displayUserLogoutInHeader = useMemo(
    () => QUERY_BACKDOOR.DISPLAY_USER_LOGOUT_IN_HEADER in query,
    [query],
  );

  return {
    displayConnectWithCredentials,
    displayAdminImportConfiguration,
    displayAuthTests,
    iAmAGuest,
    stayOnLanding,
    displayUserLogoutInHeader,
  };
};
