import React, { createRef, FC, RefObject } from "react";

import { KModal, KModalContentSection, KModalHeader } from "@keepeek/refront-components";
import Form from "@rjsf/core";
import { RJSFSchema } from "@rjsf/utils";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";

import { CONFIGURATION_SECTION_PATH } from "../../components/layout/adminMenu";
import { DRAG_N_DROP_WIDTH } from "../../lib/admin/react-json-schema/CustomBusinessFilterField";
import { POPPER_WIDTH } from "../../lib/admin/react-json-schema/CustomBusinessFilterField/ManageFilterDetail/index.styled";
import { CurrentEditKey } from "../../providers/frontEdit/atoms/frontEditCurrentEditKeys";
import useFrontEditModal from "../../providers/frontEdit/hooks/useFrontEditModal";
import FrontEditEditionForm from "./FrontEditEditionForm";

const EditionModal: FC<React.PropsWithChildren<unknown>> = function () {
  const { isOpen, setIsOpen, currentEditKeys } = useFrontEditModal();
  const formRef: RefObject<Form<any, RJSFSchema, any>> = createRef();

  const { t } = useTranslation();
  if (!isOpen) {
    return null;
  }
  return (
    <KModal
      disableEnforceFocus={true}
      scrollbarVisible={true}
      header={
        <KModalHeader
          onClose={() => {
            setIsOpen(false);
          }}
          title={t("front-edit.edition-modal.element-edition.label") as string}
        />
      }
      onClose={() => {
        setIsOpen(false);
      }}
      open={isOpen}
      sx={{
        ...(isBusinessFilterEdition(currentEditKeys) && {
          // need to have a large popup for filter edition
          [`& .KModal-content`]: {
            maxWidth: `calc(${DRAG_N_DROP_WIDTH}px + ${POPPER_WIDTH}px + 70px)`,
            [`& .rjsf`]: {
              minHeight: "650px",
            },
          },
        }),
      }}
    >
      <KModalContentSection>
        <DndProvider backend={HTML5Backend}>
          <FrontEditEditionForm keys={currentEditKeys} formRef={formRef} />
        </DndProvider>
      </KModalContentSection>
    </KModal>
  );
};

const isBusinessFilterEdition = (currentEditKey: CurrentEditKey[]): boolean =>
  currentEditKey.some(
    (currentEditKey) =>
      currentEditKey.configSection === CONFIGURATION_SECTION_PATH.COMPONENTS_BUSINESS_FILTER,
  );

export default EditionModal;
