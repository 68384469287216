import { AutoGuestLogin, KeycloakConfiguration } from "../../models/configuration/global";

/**
 *
 * @param keycloakConfiguration
 * @returns true if the keycloak button to connect with login / password is available
 */
export function isKeycloakConnectWithCredentialsEnabled(
  keycloakConfiguration: KeycloakConfiguration | undefined,
): boolean {
  return (
    !keycloakConfiguration ||
    (keycloakConfiguration &&
      (keycloakConfiguration.displayConnectWithCredentials ||
        keycloakConfiguration.displayConnectWithCredentials === undefined ||
        !keycloakConfiguration.idp ||
        keycloakConfiguration.idp.length === 0))
  );
}

export function isKeycloakConnectWithGuestAvailable(
  keycloakConfiguration: KeycloakConfiguration | undefined,
): boolean {
  return (
    !!keycloakConfiguration &&
    (!!keycloakConfiguration.displayConnectWithGuest ||
      keycloakConfiguration.autoGuestLogin === AutoGuestLogin.AutoGuestLogin ||
      keycloakConfiguration.autoGuestLogin === AutoGuestLogin.AutoGuestLoginWithParam)
  );
}

/**
 *
 * @param keycloakConfiguration
 * @returns true if at least one idp is available
 */
export function isKeycloakAutomaticConnectAvailable(
  keycloakConfiguration: KeycloakConfiguration | undefined,
): boolean {
  return !!(
    keycloakConfiguration &&
    keycloakConfiguration.idp &&
    keycloakConfiguration.idp.length >= 1
  );
}
